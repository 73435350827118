import { useState } from "react";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import Modal from "src/Components/Modal";
import styles from "./ChangeURLSlugModal.module.scss";
import axios from "axios";
import env from "../../../../../../environment.json";

/**
 * Modal to change the URL (redirect)
 *
 * @author 					Pætur Mortensen
 */
export default function ChangeURLSlugModal({ setEditSlug, slug, setNewSlug, pageLanguageID }) {
  // Value of the new slug input
  const [slugValue, setSlugValue] = useState(slug);
  // Validation error for form
  const [validationError, setValidationError] = useState("");
  // Whether modal is currently loading or sending request
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Save the slug
   *
   * Will call the server to create a redirect
   *
   * @author 					Pætur Mortensen
   */
  function save_slug() {
    // If slug has not changed, throw error
    if (slugValue === slug) {
      setValidationError("The new slug is the same as the old one");
      return;
    }

    // Set this form to loading
    setIsLoading(true);

    // Now call the API
    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", {
        pageLanguageID,
        slug: slugValue,
      })
      .then((response) => {
        // Extract the data from the API response
        const data = response.data.data;

        // If this was not successful, show validation error
        if (!data.success) {
          setValidationError(data.validationMsg);
        } else {
          // Redirect added, close and call onUpdate
          setNewSlug(slugValue);
          setEditSlug(false);
        }
      })
      .catch((error) => {
        setValidationError("Something went wrong. Try again or contact support if this keeps happening");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      title="Change URL slug"
      closeButtonText="Close"
      type="confirmation"
      close={() => {
        setEditSlug(false);
      }}
      onConfirm={() => {
        save_slug();
      }}
      onCancel={() => {
        setEditSlug(false);
      }}
      confirmButtonText="Save"
      cancelButtonText="Cancel"
      showPreloader={isLoading}
    >
      <div className={styles.container}>
        <label>Current slug</label>
        <VfiInputText inputType="text" value={slug} disabled={true} />
        <label>New slug</label>
        <VfiInputText
          inputType="text"
          value={slugValue}
          onChange={(e) => {
            setSlugValue(e.target.value);
          }}
        />
        <div className={styles.validationErrors}>{validationError}</div>
      </div>
    </Modal>
  );
}
