import { useState, useEffect } from "react";
import styles from "./Combobox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

/**
 * Simple combo select box
 *
 * @param 		{array} 		options 						Array of options for select
 * @param 		{string} 		searchPlaceholder 	Placeholder for the search input
 * @param 		{function} 	onChange 						onChange callback
 * @returns 	{JSX}
 *
 * @author 					Pætur Mortensen
 */
export default function ComboSelect({ options, searchPlaceholder = "Search...", onChange = () => {} }) {
  // Currently selected option
  const [value, setValue] = useState(null);
  // Active options (options to display in datalist)
  const [activeOptions, setActiveOptions] = useState(options);
  // Search string in the search input
  const [search, setSearch] = useState("");
  // Whether to display the datalist
  const [showList, setShowList] = useState(false);

  // Update the active options state when options are changed
  useEffect(() => {
    setActiveOptions(options);
  }, [options]);

  /**
   * On search input change event
   *
   * Search the options and update the options array with found options
   *
   * @param 		{string} 		search 								String to search for
   *
   * @author 					Pætur Mortensen
   */
  function onSearchInputChange(search) {
    // Lowercase search string for case-insensitive search
    const searchString = search.toLowerCase();
    // Split the search string by spaces into words
    const searchArr = searchString.split(" ");
    // Initialize new options array as empty
    const newOptions = [];

    /**
     * Check whether search string is found in searchable string
     *
     * This function checks each word in the search string but not necessarily in order
     *
     * @param 			{string} 		searchable 									Searchable string to search
     * @returns 		{boolean} 															Whether search string was found
     *
     * @author 					Pætur Mortensen
     */
    const stringFound = (searchable) => {
      // For each word in the search array
      for (const word of searchArr) {
        // If the word is not in the searchable string, just retur false
        if (!searchable.includes(word)) return false;
      }

      // All words have been found in the searchable string, return true
      return true;
    };

    // For each option...
    for (const index in options) {
      const option = options[index];

      // Create a searchable string from the label and any added searchable strings
      const searchable = `${option.label} ${option.searchable}`.toLowerCase();
      // Add the option if the searchable string contains all words from the search string
      if (stringFound(searchable)) newOptions.push(option);
    }

    setActiveOptions(newOptions);
    setSearch(search);
  }

  /**
   * When value is selected in the datalist
   *
   * @param 		{mixed} 		value 							Selected value
   *
   * @author 					Pætur Mortensen
   */
  function onValueSelect(value) {
    // Get the selected option element (find first match) and get its label
    const selectedOption = options.find((option) => option.value === value);
    const label = selectedOption.label;

    // Set value and search value and trigger onChange
    setValue(value);
    setSearch(label);
    onChange(selectedOption);
  }

  /**
   * Toggle the display state of the datalist
   *
   * This function delays the toggle by a few ms in order to complete other processes that set
   * state before toggling the list
   *
   * @param 			{boolean} 		toggle 								Toggle direction: true = show, false = hide
   *
   * @author 					Pætur Mortensen
   */
  function toggleList(toggle = null) {
    // Set toggle to parameter OR opposite of current state if parameter is NULL
    const toggleDirection = toggle !== null ? toggle : !showList;
    // If we're not changing toggle direction, just skip this
    if (toggleDirection === showList) return;

    // Delay this action since other state setting operations need to finish first
    window.setTimeout(function () {
      // Set the display state for the datalist
      setShowList(toggleDirection);
    }, 100);
  }

  return (
    <div className={styles.container}>
      <div className={styles.searchInputContainer}>
        <input
          type="text"
          placeholder={searchPlaceholder}
          value={search}
          className={styles.searchInput}
          onChange={(e) => {
            onSearchInputChange(e.target.value);
          }}
          onFocus={() => {
            toggleList(true);
          }}
          onBlur={() => {
            toggleList(false);
          }}
        />
        <FontAwesomeIcon
          className={styles.expandArrow}
          icon={faChevronDown}
          onClick={() => {
            toggleList();
          }}
        />
      </div>
      <div className={`${styles.datalist} ${showList && styles.show}`}>
        {activeOptions.map((option, index) => (
          <div
            className={`${styles.datalistOption} ${value === option.value && styles.selectedOption}`}
            key={index}
            onClick={() => {
              onValueSelect(option.value);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
