import styles from "../RedirectsDisplay.module.scss";
import { HeaderType } from "../types";

/**
 * Header section for redirects
 *
 * @author 					Pætur Mortensen
 */
export default function Header({ setCreateNew }: HeaderType): JSX.Element {
  return (
    <div className={styles.header}>
      <h1>Redirects</h1>
      <button
        className={styles.addRedirect}
        onClick={() => {
          setCreateNew(true);
        }}
      >
        Add new redirect
      </button>
    </div>
  );
}
