import { ChangeEvent, useState } from "react";
import { CreateRedirectFormType, PageOption, AddRedirectResponse } from "../types";
import ComboSelect from "../../../../../Inputs/ComboSelect/index";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import Modal from "../../../../../Modal/index";
import axios, { AxiosError } from "axios";
import env from "../../../../../../environment.json";
import styles from "../RedirectsDisplay.module.scss";

export default function CreateRedirectForm({
  pageOptions,
  allPages,
  createNew,
  setCreateNew,
  onUpdate,
}: CreateRedirectFormType): JSX.Element {
  // Current slug for page
  const [slug, setSlug] = useState("");
  // Redirect slug to use
  const [redirect, setRedirect] = useState("");
  // Whether the form is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Page language ID to redirect
  const [pageLanguageID, setPageLanguageID] = useState<string>("");
  // Validation error for redirect
  const [validationError, setValidationError] = useState<string>("");

  /**
   * onChange for the page select
   *
   * @author 					Pætur Mortensen
   */
  function pageSelectChange(option: PageOption) {
    const newPageLanguageID = option.value;
    const newSlug = allPages.find((page) => page.pageLanguageID.toString() === newPageLanguageID)?.slug;

    // If newSlug is not set, exit this function
    if (!newSlug) return;

    // Set slug and pageLanguageID for this redirect
    setSlug(newSlug);
    setPageLanguageID(newPageLanguageID);
  }

  /**
   * Submit the create redirect form
   *
   * @author 					Pætur Mortensen
   */
  function submit() {
    // If no page has been selected, throw error and return
    if (!pageLanguageID) {
      setValidationError("You must select a page to redirect");
      return;
    }

    // If redirect slug is empty, throw error and return
    if (!redirect) {
      setValidationError("You must set a redirect slug");
      return;
    }

    // Set this form to loading
    setIsLoading(true);

    // Now call the API
    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", { pageLanguageID, slug: redirect })
      .then((response: AddRedirectResponse) => {
        // Extract the data from the API response
        const data = response.data.data;

        // If this was not successful, show validation error
        if (!data.success) {
          setValidationError(data.validationMsg);
        } else {
          // Redirect added, close and call onUpdate
          onUpdate();
          onClose();
        }
      })
      .catch((error: AxiosError) => {
        setValidationError("Something went wrong. Try again or contact support if this keeps happening");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Reset the form to initial state
   *
   * @author 					Pætur Mortensen
   */
  function reset_form() {
    setSlug("");
    setRedirect("");
    setIsLoading(false);
    setPageLanguageID("");
    setValidationError("");
  }

  /**
   * onClose event for the form
   *
   * @author 					Pætur Mortensen
   */
  function onClose() {
    // Used to close the form
    setCreateNew(false);
    // Reset the form and all values
    reset_form();
  }

  // Return empty if we are not creating new redirect
  if (!createNew) return <></>;

  return (
    <Modal
      title="Create Redirect"
      type="confirmation"
      close={onClose}
      onConfirm={submit}
      onCancel={onClose}
      confirmButtonText="Create redirect"
      cancelButtonText="Cancel"
      showPreloader={isLoading}
    >
      <div className={styles.createForm}>
        <div className={styles.formField}>
          <ComboSelect
            options={pageOptions}
            searchPlaceholder="Find page..."
            onChange={pageSelectChange as () => void}
          />
        </div>
        <div className={styles.formField}>
          <label>Current slug</label>
          <VfiInputText disabled={true} value={slug} />
        </div>
        <div className={styles.formField}>
          <label>Redirect to</label>
          <VfiInputText
            placeholder="Redirect..."
            value={redirect}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setRedirect(e.target.value);
            }}
          />
        </div>
        <div className={styles.validationError}>{validationError}</div>
      </div>
    </Modal>
  );
}
