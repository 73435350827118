import { useState, useEffect } from "react";
import styles from "./RedirectsDisplay.module.scss";
import axios, { AxiosError } from "axios";
import env from "../../../../../environment.json";
import { PageItem, AllPagesResponse, PageOption } from "./types";
import CreateRedirectForm from "./RedirectModules/CreateRedirectForm";
import Preloader from "src/assets/Preloader";
import Header from "./RedirectModules/Header";
import Search from "./RedirectModules/Search";
import RedirectTable from "./RedirectModules/RedirectTable";

export default function RedirectsDisplay() {
  const [allPages, setAllPages] = useState<PageItem[]>([]);
  const [pageOptions, setPageOptions] = useState<PageOption[]>([]);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirectPages, setRedirectPages] = useState<PageItem[]>([]);
  const [tablePages, setTablePages] = useState<PageItem[]>([]);

  useEffect(() => {
    fetch_all_page_redirects();
  }, []);

  /**
   * Fetch all pages and their redirects
   *
   * @author 					Pætur Mortensen
   */
  function fetch_all_page_redirects() {
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/GetPageRedirectList")
      .then((response: AllPagesResponse) => {
        update_redirect_pages(response.data.data);
      })
      .catch((error: AxiosError) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Update the lists of redirect pages, select options etc.
   *
   * Called after calling the API for this list
   *
   * @author 					Pætur Mortensen
   */
  function update_redirect_pages(pages: PageItem[]): void {
    // Get list of all pages from parameter and initialize select list as empty
    const allPages = pages;
    // Pages with no redirects are added to the select
    const selectPages = [];
    // Pages with redirects are added to the table
    const redirectPages = [];

    // Iterate over pages and add to select if they apply
    for (const page of allPages) {
      // Extract page information
      const pageLanguageID = page.pageLanguageID;
      const language = page.language;
      const title = page.title || "NO TITLE";

      // If the page does not have any redirects, add it to the select..
      if (page.redirects.length === 0) {
        // Create a single page option
        const pageOption = {
          value: pageLanguageID.toString(),
          label: `${title} [${language}] - ${page.siteName}`,
          searchable: `${page.pageID} ${language} ${pageLanguageID} ${page.slug} ${page.siteName} ${page.siteDomainName}`,
        };
        selectPages.push(pageOption);
      } else {
        // If the page has redirects, add it to the table
        page.title = title;
        // Build the searchable string for the table
        page.searchable = `${page.pageID} ${page.status} ${title} ${page.slug} ${language} ${pageLanguageID} ${page.siteName} ${page.siteDomainName}`;
        // Add redirects to the searchable string
        for (const redirect of page.redirects) {
          page.searchable += ` ${redirect.slug}`;
        }

        redirectPages.push(page);
      }
    }

    setAllPages(allPages);
    setPageOptions(selectPages);
    setRedirectPages(redirectPages);
    setTablePages(redirectPages);
  }

  /**
   * onUpdate for the redirects. This is called whenever there is an update on the data
   *
   * @author 					Pætur Mortensen
   */
  function onUpdate() {
    fetch_all_page_redirects();
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Header setCreateNew={setCreateNew} />
        <Search allPages={redirectPages} setTablePages={setTablePages} />
        <Preloader show={isLoading} />
        {!isLoading && (
          <RedirectTable
            redirectPages={tablePages}
            fetchAllPageRedirects={fetch_all_page_redirects}
            setIsLoading={setIsLoading}
          />
        )}
      </div>

      {/* Create Redirect Modal */}
      <CreateRedirectForm
        pageOptions={pageOptions}
        allPages={allPages}
        createNew={createNew}
        setCreateNew={setCreateNew}
        onUpdate={onUpdate}
      />
    </div>
  );
}
