type BaseInputConfig = {
  placeholder: string;
  tooltip?: string | JSX.Element;
  textLimit?: number;
};

type TextInputConfig = BaseInputConfig & {
  inputType: "text";
};

type TextAreaInputConfig = BaseInputConfig & {
  inputType: "textarea";
  height?: number;
};

type InputConfig = TextInputConfig | TextAreaInputConfig;

export const inputConfigs: { [key: string]: InputConfig } = {
  headlineTextInput: {
    inputType: "text",
    placeholder: "Headline",
    tooltip: "Keep it short and sweet.",
    textLimit: 50,
  },
  shortSummaryTextInput: {
    inputType: "textarea",
    placeholder: "Introduction",
    tooltip: (
      <div style={{ textAlign: "left" }}>
        Your audience will see the introduction before the description on the event page.
        <br />
        <br />
        Give a short introduction with an overview that naturally flows into the description field below.
      </div>
    ),
    textLimit: 80,
    height: 100,
  },
  descriptionTextInput: {
    inputType: "textarea",
    placeholder: "Description",
    tooltip: (
      <div style={{ textAlign: "left" }}>
        Your audience will see the description on the place page.
        <br />
        <br />
        It's a good idea to start with a general overview of your place, followed by details. Keep the description light
        and engaging, and try to think about your audience's perspective.
      </div>
    ),
    textLimit: 3000,
    height: 260,
  },
  mapSummaryTextInput: {
    inputType: "textarea",
    placeholder: "Map Summary",
    tooltip: "Your audience will see this summary of the event in the map overview.",
    textLimit: 300,
    height: 100,
  },
};
