import Alert from "./Elements/Alert/Alert";
import Card from "./Elements/Card/Card";
import SidePopoutSelfClose from "./Elements/SidePopout/SidePopoutSelfClose";
import Spinner from "./Elements/Spinner/Spinner";
import { IOverlayProperties } from "./types";

export function createOverlay(properties: IOverlayProperties): any {
  switch (properties?.type) {
    case "card":
      return (
        <Card key={`card-${properties.id}`} close={properties.close}>
          {properties.overlay}
        </Card>
      );
    case "side_popout_self_close":
      return (
        <SidePopoutSelfClose key={`side-popout-${properties.id}`} close={properties.close}>
          {properties.overlay}
        </SidePopoutSelfClose>
      );
    case "alert":
      return (
        <Alert key={`alert-${properties.id}`} close={properties.close}>
          {properties.overlay}
        </Alert>
      );
    case "spinner":
      return (
        <Spinner key={`key-${properties.id}`} close={properties.close}>
          {properties.children}
        </Spinner>
      );
    default:
      return (
        <Card key={`card-${properties.id}`} close={properties.close}>
          {properties.overlay}
        </Card>
      );
  }
}
