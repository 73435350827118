import React, { useState } from "react";
import styles from "../RedirectsDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faPencil, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Modal from "src/Components/Modal";
import { RedirectTableType, PageRedirectType, RedirectTableRowType } from "../types";
import axios, { AxiosError } from "axios";
import env from "../../../../../../environment.json";
import { faOilWell, faPlus } from "@fortawesome/pro-regular-svg-icons";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";

export default function RedirectTable({
  redirectPages,
  fetchAllPageRedirects,
  setIsLoading,
}: RedirectTableType): JSX.Element {
  const [confirmDeleteRedirect, setConfirmDeleteRedirect] = useState<Number>(0);

  /**
   * Delete a redirect from the backend
   *
   * @author    Alvin Mwakai
   */
  function delete_redirect() {
    const redirectID = confirmDeleteRedirect;

    if (!redirectID) {
      return;
    }

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/DeleteRedirect", { redirectID })
      .then(() => {
        fetchAllPageRedirects();
      })
      .catch((error: AxiosError) => {
        console.error("Error deleting redirect:", error);
      })
      .finally(() => {
        setConfirmDeleteRedirect(0);
      });
  }

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>PAGE</th>
            <th>Redirect to</th>
            <th>Redirect from</th>
          </tr>
        </thead>
        <tbody>
          {redirectPages.map((page) => {
            return (
              <RedirectTableRow
                page={page}
                key={page.pageLanguageID.toString()}
                confirmDeleteRedirect={(redirectID: Number) => {
                  setConfirmDeleteRedirect(redirectID);
                }}
                fetchAllPageRedirects={fetchAllPageRedirects}
                setIsLoading={setIsLoading}
              />
            );
          })}
        </tbody>
      </table>

      {/* Delete Redirect Modal */}
      {confirmDeleteRedirect !== 0 && (
        <Modal
          title="Delete Redirect"
          type="confirmation"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          close={() => {
            setConfirmDeleteRedirect(0);
          }}
          onConfirm={delete_redirect}
          onCancel={() => {
            setConfirmDeleteRedirect(0);
          }}
        >
          <div className={styles.modalContent}>
            <p>Are you sure you want to delete this redirect? Any links to this slug will be dead</p>
          </div>
        </Modal>
      )}
    </div>
  );
}

/**
 * Render a redirect table row
 */
function RedirectTableRow({
  page,
  confirmDeleteRedirect,
  fetchAllPageRedirects,
  setIsLoading,
}: RedirectTableRowType): JSX.Element {
  const [editing, setEditing] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>(page.slug);
  const [pageLanguageID] = useState<Number>(page.pageLanguageID);
  const [addRedirect, setAddRedirect] = useState<boolean>(false);
  const [addRedirectSlug, setAddRedirectSlug] = useState<string>("");
  const [addRedirectValidationError, setAddRedirectValidationError] = useState<string>("");

  /**
   * onChange when updating a slug
   *
   *
   * @author    Alvin Mwakai
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.currentTarget.value);
  };
  const cancel = () => {
    setSlug(page.slug);
    setEditing(false);
  };

  /**
   * Called when the save button is clicked
   *
   */
  function editSlug() {
    if (slug !== page.slug) {
      edit_redirect(page.slug, slug);
    } else {
      setEditing(false);
    }
  }

  /**
   * Edits or Updates the slug in the backend
   *
   * @author    Alvin Mwakai
   */
  function edit_redirect(oldSlug: string, newSlug: string) {
    if (!oldSlug || !newSlug || !pageLanguageID) {
      return;
    }

    // Start the loading spinner here
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", {
        oldSlug,
        newSlug,
        pageLanguageID,
        slug: newSlug,
      })
      .then(() => {
        fetchAllPageRedirects();
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }

  /**
   * Add a redirect to this page
   *
   * @author 					Pætur Mortensen
   */
  function add_redirect_to_page() {
    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddRedirectToPage", {
        slug: addRedirectSlug,
        pageLanguageID,
      })
      .then((response) => {
        const data = response.data.data;
        if (!data.success) {
          setAddRedirectValidationError(data.validationMsg);
        } else {
          fetchAllPageRedirects();
        }
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }

  return (
    <tr>
      <td>
        {page.title} [{page.language}] - {page.siteName}
      </td>
      <td>
        {editing ? (
          <div className={styles.editContainer}>
            <input type="text" value={slug} onChange={onChange} autoFocus />
            <div className={styles.buttonContainer}>
              <button className={styles.saveButton} onClick={editSlug}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
              </button>
              <button className={styles.cancelButton} onClick={cancel}>
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </button>
            </div>
          </div>
        ) : (
          <>
            {slug}
            <button
              className={styles.edit}
              onClick={() => {
                setEditing(true);
              }}
            >
              <FontAwesomeIcon icon={faPencil as IconProp} />
            </button>
          </>
        )}
      </td>
      <td>
        {page.redirects.map((redirect) => {
          return (
            <div className={styles.redirectItem}>
              {redirect.slug}
              <button
                className={styles.delete}
                onClick={() => {
                  confirmDeleteRedirect(redirect.redirectID);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt as IconProp} />
              </button>
            </div>
          );
        })}
        {addRedirect ? (
          <div className={styles.addRedirectForm}>
            <VfiInputText
              value={addRedirectSlug}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAddRedirectSlug(e.target.value);
              }}
            />
            <div className={styles.buttonContainer}>
              <button
                className={styles.saveButton}
                onClick={() => {
                  add_redirect_to_page();
                }}
              >
                <FontAwesomeIcon icon={faCheck as IconProp} />
              </button>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  setAddRedirect(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </button>
            </div>
            {addRedirectValidationError !== "" && (
              <div className={styles.validationError}>{addRedirectValidationError}</div>
            )}
          </div>
        ) : (
          <button
            className={styles.addButton}
            onClick={() => {
              setAddRedirect(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus as IconProp} />
          </button>
        )}
      </td>
    </tr>
  );
}
