import VerifyMethods from "src/assets/VerifyMethods";
import { TLanguages } from "../../Steps";
import { DescriptionState } from "./DescriptionStore";
import { inputConfigs } from "../../Steps/Common/description/utils";

const FORM_REQIREMENTS = {
  headlineTextInput: {
    min: 3,
  },
  shortSummaryTextInput: {
    min: 10,
  },
  descriptionTextInput: {
    min: 70,
  },
  mapSummaryTextInput: {
    min: 10,
  },
};

export function ValidateDescription(
  descritpion: Record<TLanguages, DescriptionState>,
  enabled: Record<TLanguages, boolean>
): {
  valid: boolean;
  description: Record<TLanguages, DescriptionState>;
} {
  let newDescription = { ...descritpion };
  let least_one_enabled = false;
  let valid = true;

  Object.keys(descritpion).forEach((language) => {
    if (!enabled[language as TLanguages]) return;

    if (valid) least_one_enabled = true;

    const description = descritpion[language as TLanguages];

    Object.keys(description).forEach((key) => {
      const input = description[key as keyof DescriptionState];

      const error = VerifyMethods.minChars(input.value, FORM_REQIREMENTS[key as keyof typeof FORM_REQIREMENTS].min);
      description[key as keyof DescriptionState].error = error;
      if (valid) valid = error === "";
    });
  });

  if (!least_one_enabled) {
    valid = false;
  }

  return {
    valid: valid,
    description: newDescription,
  };
}
